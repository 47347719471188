import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Wrapper from 'components/wrapper';
import { fetchData } from 'lib/fetchData';
import type { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';

interface IProps {
  locale: string;
  menu: any;
  company: any;
  footer: any;
}

const P404: NextPage<IProps> = (props) => {
  const { menu, company, footer } = props;
  const router = useRouter();
  return (
    <Wrapper menu={menu} company={company} footer={footer}>
      <Head>
        <title>{company.name}</title>
        <meta name="description" content={company.description} />
        <link rel="icon" href={company.favicon.media.url} />
        <meta
          property="article:published_time"
          content={new Date().toLocaleTimeString()}
        />
        <meta
          name="publish_date"
          property="og:publish_date"
          content={new Date().toLocaleDateString()}
        />
        <meta
          name="author"
          property="article:author"
          content="Luccin Masirika"
        />
      </Head>
      <Stack
        sx={{ width: '100%', height: '50vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h1">404</Typography>
        <Typography variant="h3">
          {router.locale === 'fr' ? 'Page non trouvée' : 'Page not found'}
        </Typography>
      </Stack>
    </Wrapper>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;
  const menu = await fetchData(`/menu/?_locale=${locale}`);
  const company = await fetchData(`/company/?_locale=${locale}`);
  const footer = await fetchData(`/footer/?_locale=${locale}`);

  return {
    props: {
      menu,
      company,
      footer,
    },
    revalidate: 10,
  };
};

export default P404;
